<template>
  <div class="row">
    <div class="col-lg-6 col-sm-12">
      <b-row class="mb-2">
        <b-col>
          <h5>Title Category</h5>
          <b-form-input v-model="news.title"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex justify-content-center">
          <b-button variant="primary" @click="save">SAVE NEWS CATEGORY</b-button>
        </b-col>
      </b-row>
    </div>
    <div class="col-lg-6 col-sm-12 table-responsive">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th colspan="3">List Categories</th>
        </tr>
        <tr>
          <th>Title</th>
          <th>Options</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in arrayCategories" :key="index">
          <td>{{ item.title }}</td>
          <td>
            <span size="sm" class="material-icons text-primary" style="cursor: pointer; "
                  @click="removeCategory(item._id)">clear</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {apiUrl, get, post} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "CreateNews",
  mixins: [show_alert_mixin],
  props: {
    arrayCategories: {default: []}
  },
  data: () => ({
    roles: ['distributor', 'dealer', 'subdealer'],
    rolesSelected: [],
    news: {
      title: null,
    },
  }),
  methods: {
    async save() {
      if (this.news.title !== null) {
        const {data} = await post("news/create-category", this.news, true)
        if (data === 200) {
          this.showAlertNotification("information saved")
          this.news.title = null;
          this.$emit('reloadCategories')
        } else {
          this.showAlertNotification("The category is already registered", 'error')
        }
      } else this.showAlertNotification('All fields are required', 'error')
    },
    async removeCategory(id) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Are you sure?',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async res => {
        if (res.isConfirmed) {
          await post("news/delete-category", {id: id}, true)
          this.$emit('reloadCategories')
          this.showAlertNotification("information deleted")
        }
      });
    },
  }

}
</script>

<style scoped>

</style>
