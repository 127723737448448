import Vue from 'vue';
import {get} from "@/services/api";
import ListNews from './admin/ListNews'
import CreateNews from './admin/CreateNews'
import ListHelp from './admin/ListHelp'
import CreateCategories from './admin/CreateCategories'
import ListCategories from './client/Index'
import FAQS from './admin/CreateFaqs'
import CategoriesFaqs from './admin/CategoriesFaqs'
import {decrypt} from "@/helpers/encrypt";


export default Vue.extend({
  name: 'News',
  components: {
    ListNews,
    CreateNews,
    CreateCategories,
    ListCategories,
    FAQS,
    CategoriesFaqs,
    ListHelp
  },
  data: () => ({
    arrayNews: [],
    arrayCategories: [],
    role: null
  }),
  mounted() {
    const rol = localStorage.getItem('admin-type')
    this.role = JSON.parse(decrypt(rol))

    if (this.$userCan(['administrator', 'calibrator', 'support'])) {
      this.getNews().then()
      this.getCategories().then()
    } else {
      this.getNews().then(() => {
        this.filterCategories()
      })
    }
  },
  methods: {
    async getNews() {
      const {data} = await get("news/index", null, true)
      this.arrayNews = data
    },
    async getCategories() {
      const {data} = await get('news/get-category', null, true)
      this.arrayCategories = data
    },
    filterCategories() {
      this.arrayNews.map(item => {
        const response = item.roles.find(x => x == this.role[0]);
        if (!!response) {
          const category = this.arrayCategories.find(x => x._id === item.category._id)
          if (category === undefined)
            this.arrayCategories.push(item.category)
        }
      })
    }
  },

});
