<template>
  <div>
    <b-row class="mb-2">
      <b-col class="col-lg-4 col-md-4 col-sm-12">
        <label>Title</label>
        <b-form-input v-model="form.title"></b-form-input>
        <label>Select categories</label>
        <b-form-select v-if="arrayCategories.length" v-model="form.category" class="mb-3">
          <b-form-select-option :value="item._id" v-for="(item, index) in arrayCategories" :key="index">
            {{ item.title }}
          </b-form-select-option>
        </b-form-select>
        <div style="width: 100%">
          <label>File</label>
        </div>
        <input id="myFile" ref="file" type="file" @change="onSelect($event)" @click="resetInputFile($event)">

        <div class="text-right mt-2">
          <b-button v-if="!actionEdit" class="btn-block" variant="primary" @click="save">SAVE HELP</b-button>
        </div>
      </b-col>
      <b-col class="col-lg-7 col-md-7 col-sm-12 table-responsive">
        <div style="width: 100%">
          <label>Text</label>
        </div>
        <ckeditor v-model="form.body"></ckeditor>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {get, post} from "@/services/api";
import {mapMutations} from "vuex";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "ListFaqs",
  mixins: [show_alert_mixin],
  data: () => ({
    form: {
      type: 'instagram',
      url: null,
      title: null,
      body: null,
      category: null
    },
    arrayData: [],
    arrayCategories: [],
    actionEdit: false
  }),
  mounted() {
    this.getFaqsCategories()
  },
  methods: {
    ...mapMutations(['loaderManager']),
    async save() {
      if (!!this.file && !!this.form.title && !!this.form.body) {
        this.loaderManager(true);
        const formData = new FormData();
        formData.append('file', this.file);
        formData.append('title', this.form.title);
        formData.append('body', this.form.body);
        formData.append('category', this.form.category);
        post('news/save-instagram-url', formData, true)
          .then(({data}) => {
            location.reload();
          }).catch((error) => {
          this.loaderManager(false);
        });
      } else this.showAlertNotification('All fields are required', 'error')
    },
    clearForm() {
      this.actionEdit = false
      this.form = {
        url: null,
        title: null,
        body: null,
      }
    },

    async getFaqsCategories() {
      this.arrayCategories = []
      const {data} = await get("news/get-faqs-categories", null, true)
      this.arrayCategories = data
    },

    onSelect(e) {
      this.file = e.target.files.item(0);
    },

    resetInputFile(e) {
      e.target.value = '';
    },
  }
}
</script>

<style scoped>

</style>
