<template>
  <div>
    <b-row class="mb-2">
      <b-col>
        <h5>Title</h5>
        <b-form-input v-model="news.title"></b-form-input>
      </b-col>
      <b-col>
        <h5>Select roles</h5>
        <b-form-group>
          <b-form-checkbox class="checkRol" @change="addOption($event, item)" name="role-radios" :value="item"
                           v-for="(item, index) in roles" :key="index">
            {{ item.toUpperCase() }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col>
        <h5>Select category</h5>
        <b-form-select v-model="selectedCategory" class="mb-3">
          <b-form-select-option :value="null">Please select an option</b-form-select-option>
          <b-form-select-option :value="item._id" v-for="(item, index) in arrayCategories">{{ item.title }}
          </b-form-select-option>
        </b-form-select>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col>
        <h5>Description</h5>
        <ckeditor v-model="news.body" :config="editorConfig"></ckeditor>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-center">
        <b-button variant="primary" @click="save">SAVE NEWS</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {apiUrl, get, post} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "CreateNews",
  mixins: [show_alert_mixin],
  props: {
    arrayCategories: {default: []}
  },
  data: () => ({
    roles: ['distributor', 'dealer', 'subdealer'],
    rolesSelected: [],
    selectedCategory: null,
    news: {
      title: null,
      body: null,
      roles: [],
      category: null
    },
    editorConfig: {
      extraPlugins: 'uploadimage', // 'filebrowser',
      filebrowserBrowseUrl: `${apiUrl}news/upload-image`,
      filebrowserUploadUrl: `${apiUrl}news/upload-image`
    },
  }),
  methods: {
    async save() {
      if (this.news.title !== null && this.news.body !== null && this.rolesSelected.length > 0 && this.selectedCategory !== null) {
        this.news.roles = this.rolesSelected
        this.news.category = this.selectedCategory
        const {data} = await post("news/create", this.news, true)
        if (data === 200) {
          this.$emit("reloadNews")
          this.showAlertNotification("Information saved")
          setTimeout(() => {
            location.reload()
          }, 1500)
        }
      } else {
        this.showAlertNotification("All fields are required", "error")
      }
    },
    clearForm() {
      this.news = {
        title: null,
        body: null,
        roles: [],
      }
    },
    addOption(e, item) {
      if (typeof e === 'string') {
        this.rolesSelected.push(item)
      } else {
        let i = this.rolesSelected.indexOf(item);
        this.rolesSelected.splice(i, 1);
      }
    },
  }

}
</script>

<style scoped>

</style>
