<template>
  <b-row>
    <div class="col-lg-6 col-sm-12 table-responsive">
      <table class="table table-bordered table-hover table-sm">
        <thead>
        <tr>
          <th>Title</th>
          <th>Category</th>
          <th>Text</th>
        </tr>
        </thead>
        <tbody v-if="arrayData.length">
        <tr @click="showFaq(item._id)" style="cursor: pointer" v-for="(item, index) in arrayData">
          <td>{{ item.title }}</td>
          <td>{{ item.category.title }}</td>
          <td v-html="(item.body).substring(0,100)"></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="col-lg-6 col-sm-12">
      <b-row v-if="faqSelected">
        <b-col class="text-center">
          <h5>{{ faqSelected.title }}</h5>
        </b-col>
      </b-row>
      <b-row v-if="faqSelected">
        <b-col>
          <h6>{{ faqSelected.category.title }}</h6>
        </b-col>
      </b-row>
      <b-row v-if="faqSelected">
        <b-col>
          <img style="width: 100%;" :src="faqSelected.url" alt="">
          <div v-if="faqSelected" v-html="faqSelected.body"></div>
        </b-col>
      </b-row>
      <b-row v-if="faqSelected">
        <b-col class="text-center">
          <b-button @click="removeFaq" variant="danger" class="btn">Delete</b-button>
        </b-col>
      </b-row>
    </div>
  </b-row>
</template>

<script>
import {get, post} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "ListHelp",
  mixins: [show_alert_mixin],
  data: () => ({
    arrayData: [],
    faqSelected: null
  }),
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.arrayData = []
      const {data} = await get("news/get-faqs", null, true)
      this.arrayData = data
    },

    async showFaq(id) {
      this.faqSelected = this.arrayData.find(x => x._id == id)
    },

    async removeFaq() {
      await post("news/delete-instagram-url", {id: this.faqSelected._id}, true)
      await this.getData()
      this.faqSelected = null
      this.showAlertNotification('information deleted')
    },
  }
}
</script>

<style scoped>

</style>
