<template>
  <b-row class="page-header py-4">
    <b-col class="text-center text-sm-left col-lg-4 col-md-4 col-sm-12">
      <div v-if="rowsShowed.length" class="user-activity__item py-3" v-for="(item, index) in rowsShowed"
           :key="item._id">
        <div class="user-activity__item__icon" v-if="itemSelected">
          <i v-if="item._id === itemSelected._id" class="material-icons" style="color:#d14343;"
             :id="item._id">&#xE5CA;</i>
          <i v-if="item._id !== itemSelected._id" class="material-icons" :id="item._id">&#xE5CA;</i>
        </div>
        <div class="user-activity__item__content" style="width: 100%;">
          <div class="d-flex justify-content-between">
            <span class="text-light"> {{ item.createdAt | localeDate }} </span>
            <span class="text-light" v-if="item.category"> {{ item.category.title }} </span>
          </div>
          <p @click="view(item)" style="cursor: pointer">{{ item.title }}</p>
          <div v-if="$userCan(['administrator'])">
            <b-row>
              <b-col>
                <span class="mr-1" v-for="rol in item.roles">{{ rol }}</span>
              </b-col>
              <b-col class="d-flex justify-content-end">
                <span size="sm" class="material-icons text-primary"
                      style="cursor: pointer; margin-right: 0.5vw; font-size: 1.2rem"
                      @click="removeNew(item._id)">clear</span>
                <span size="sm" class="material-icons text-primary"
                      style="cursor: pointer; margin-right: 0.5vw; font-size: 1.2rem"
                      @click="updateNew(item)">edit</span>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <b-pagination
        v-model="currentPage"
        @change="newPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        align="fill"
      ></b-pagination>
    </b-col>
    <b-col cols="col-lg-2 col-md-2 col-sm-12"></b-col>
    <b-col class="col-lg-6 col-md-6 col-sm-12 line" v-if="itemSelected && !updateItem">

      <h4 class="text-danger">{{ itemSelected.title }}</h4>
      <p class="text-secondary" style="line-height: 1.8;">
      <div class="table-responsive" v-html="itemSelected.body"></div>
    </b-col>
    <b-col class="text-center text-sm-left col-lg-6 col-md-6 col-sm-12" v-if="itemSelected && updateItem">

      <b-row class="mb-2">
        <b-col>
          <h5>Title</h5>
          <b-form-input v-model="itemSelected.title"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h6>Select new user roles</h6>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-checkbox @change="addOption($event, rol)" :aria-describedby="ariaDescribedby" name="some-radios"
                             :value="rol" v-for="(rol, index) in roles" :key="index">
              {{ rol.toUpperCase() }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col>
          <h6>Currents roles</h6>
          <p class="mr-1" v-for="rol in itemSelected.roles">{{ rol }}</p>
        </b-col>
        <b-col>
          <h5>Select category</h5>
          <b-form-select v-model="selectedCategory" class="mb-3">
            <b-form-select-option :value="null">Please select an option</b-form-select-option>
            <b-form-select-option :value="item._id" v-for="(item, index) in arrayCategories">{{ item.title }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <h5>Description</h5>
          <ckeditor v-model="itemSelected.body" :config="editorConfig"></ckeditor>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex justify-content-center">
          <b-button variant="primary" @click="save">SAVE</b-button>
        </b-col>
      </b-row>

    </b-col>
  </b-row>
</template>

<script>
import {post} from "@/services/api";
import CreateNews from "@/views/dashboard/news/admin/CreateNews";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "ListNews",
  mixins: [show_alert_mixin],
  components: {
    CreateNews
  },
  props: {
    arrayNews: {
      default: [],
    },
    itemSelected: null,
    updateItem: false,
    arrayCategories: {default: []}
  },
  mounted() {
    this.itemSelected = this.arrayNews[0]
    this.totalSizeNews = this.arrayNews.length
  },
  computed: {
    rows() {
      return this.arrayNews.length
    },
    rowsShowed() {
      return this.arrayNews.slice((this.currentPage - 1) * this.perPage, (this.currentPage - 1) * (this.perPage) + this.perPage)
    }
  },
  data: () => ({
    editorConfig: {},
    rolesSelected: [],
    selectedCategory: null,
    roles: ['distributor', 'dealer', 'subdealer'],
    perPage: 5,
    currentPage: 1,
    totalSizeNews: 0
  }),
  methods: {
    view(item) {
      this.itemSelected = item
    },
    async save() {
      if (this.itemSelected.title !== null && this.itemSelected.body !== null && this.selectedCategory !== null) {
        await post("news/update", {
          id: this.itemSelected._id,
          title: this.itemSelected.title,
          body: this.itemSelected.body,
          roles: (this.rolesSelected.length > 0) ? this.rolesSelected : this.itemSelected.roles,
          category: this.selectedCategory
        }, true)
        this.clearForm()
        this.$emit("reloadNews")
        this.showAlertNotification("information updated")
      }
    },
    async removeNew(id) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Are you sure?',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async res => {
        if (res.isConfirmed) {
          const {data} = await post("news/delete", {id: id}, true)
          this.$emit("reloadNews")
          this.showAlertNotification("information deleted")
        }
      });
    },
    updateNew(item) {
      this.updateItem = true
      this.itemSelected = item
    },
    addOption(e, item) {
      if (typeof e === 'string') {
        this.rolesSelected.push(item)
      } else {
        let i = this.rolesSelected.indexOf(item);
        this.rolesSelected.splice(i, 1);
      }
    },
    clearForm() {
      this.itemSelected = null
      this.updateItem = false
      this.rolesSelected = []
    },
    newPage(e) {
      this.currentPage = e;
    }
  },
}
</script>

<style scoped lang="scss">
.line {
  border: 1px black solid;
  border-style: dotted;
}
</style>
