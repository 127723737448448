<template>
  <div style="width: 100%">
    <b-row class="mb-2">
      <b-col class="col-lg-4 col-md-4 col-sm-12">
        <b>Select a category</b>
        <b-form-select v-model="selectedCategory" class="mb-3" @change="filterNews">
          <b-form-select-option :value="null">All news</b-form-select-option>
          <b-form-select-option :value="item._id" v-for="(item, index) in arrayCategories">{{ item.title }}
          </b-form-select-option>
        </b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-lg-6 col-md-6 col-sm-12">
        <b-overlay :show="showLoader" rounded="sm">
          <ul class="list-group">
            <li class="list-group-item" style="cursor: pointer" v-for="(item, index) in arrayNews " :key="index" @click="selectNews(item._id)">
              <h6 class="font-weight-bold">{{ item.title.substring(0,100) }}</h6>
             <div class="row">
               <div class="col"><span class="font-weight-bolder">{{(item.category.title).substring(0,100)}}</span></div>
               <div class="col text-right"><span class="font-weight-bolder"> {{item.createdAt|localDateShort}}</span></div>
             </div>
              <p v-html="(item.body).substring(0,250)"></p>
            </li>
          </ul>
        </b-overlay>
      </b-col>
      <b-col class="col-lg-6 col-md-6 col-sm-12">
        <b-card>
          <div v-if="itemSelected" style="width: 100%">
            <h4 class="text-black">{{ itemSelected.title }}</h4>
          </div>
          <div v-if="itemSelected" style="width: 100%; display: flex; justify-content: space-between">
            <p><span class="text-black font-weight-bold">Category: </span><span>{{ itemSelected.category.title }}</span></p>
            <p>
              <span class="text-black font-weight-bold">Date: </span>
              <span>{{itemSelected.createdAt|localeDate }}</span>
            </p>
          </div>
          <div v-if="itemSelected" style="width: 100%" v-html="itemSelected.body"></div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {get} from "@/services/api";

export default {
  name: "ListCategories",
  props: {
    arrayCategories: {default: []},
    arrayNews: {default: []},
  },
  data: () => ({
    selectedCategory: null,
    itemSelected: null,
    showLoader: false
  }),
  mounted() {
    this.itemSelected = this.arrayNews[0]
  },
  methods: {
    selectNews(_id) {
      this.itemSelected = this.arrayNews.find(x => x._id == _id)
    },
    async filterNews() {
      const news = await this.getNews()
      if (!!this.selectedCategory) {
        this.arrayNews = news.filter(x => x.category._id == this.selectedCategory)
      } else {
        this.arrayNews = news
      }
      this.showLoader = false
      this.itemSelected = this.arrayNews[0]
    },
    async getNews() {
      return new Promise(async resolve => {
        this.showLoader = true;
        const {data} = await get("news/index", null, true)
        resolve(data)
      })
    },
  }
}
</script>

<style scoped lang="scss">
.card-title {
  color: black;
  margin-bottom: 0 !important;
}

.card_body {
  padding: 0.5rem 1rem;
  text-align: justify;
}
</style>
