<template>
  <div class="row">
    <div class="col-lg-6">
      <b-row class="mb-2">
        <b-col>
          <h5>Title Category</h5>
          <b-form-input v-model="form.name"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex justify-content-center">
          <b-button variant="primary" @click="save">SAVE HELP CATEGORIES</b-button>
        </b-col>
      </b-row>
    </div>
    <div class="col-lg-6">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th>TITLE</th>
          <th>OPTIONS</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in arrayData">
          <td>{{ item.title }}</td>
          <td>
            <span size="sm" class="material-icons text-primary"
                  style="cursor: pointer; margin-right: 0.5vw; font-size: 1.2rem" @click="removeCategories(item._id)">clear</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {get, post} from "@/services/api";
import {mapMutations} from "vuex";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "CategoriesFaqs",
  mixins: [show_alert_mixin],
  data: () => ({
    form: {
      name: null
    },
    arrayData: []
  }),
  mounted() {
    this.getData()
  },
  methods: {
    ...mapMutations(['loaderManager']),
    async save() {
      if (!!this.form.name) {
        await post("news/create-faqs-categories", this.form, true)
        await this.getData()
        this.form.name = null
      }else this.showAlertNotification("All fields are required",'error')
    },
    async getData() {
      this.arrayData = []
      const {data} = await get("news/get-faqs-categories", null, true)
      this.arrayData = data
    },
    async removeCategories(id) {

      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Are you sure?',
        html: 'The information related will be removed too.',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await post("news/delete-faqs-categories", {id: id}, true)
            await this.getData()
            this.showAlertNotification('information deleted')
            location.reload()
          }
        });
    },
  }
}
</script>

<style scoped>

</style>
